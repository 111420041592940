.str-video {
  background-color: black;
  color: #ffffff;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
  --str-video__primary-color: black;
  overflow: hidden;
  position: relative;
}

.str-video__call-controls {
  position: absolute;
  bottom: 2vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  width: 100%;
}

.str-video__call-controls button {
  margin: 0 0.5vw;
}

.main-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.secondary-view {
  position: absolute;
  z-index: 2;
  border-radius: 8px;
  overflow: hidden;
  cursor: move;
  user-select: none;
  bottom: 10vh;
  right: 4vw;
}

.str-video__participant-view {
  width: 100%;
  height: 100%;
}

.str-video__participant-view video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
}

.video-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  height: 100%;
  width: 100%;
  font-size: clamp(1rem, 2vw, 1.5rem);
}

.participant-name {
  font-size: clamp(0.8rem, 1.5vw, 1.2rem);
  background: rgba(0, 0, 0, 10);
  color: white;
  border-radius: 4px;
  padding: 0.8vh 0.8vw;
  position: absolute;
  top: 0.8vh;
  left: 0.2vw;
  z-index: 3;
}

@media (max-width: 600px) {
  .secondary-view {
    width: 35vw;
    height: calc(35vw * 0.75);
    bottom: 10vh;
    right: 4vw;
  }

  .participant-name {
    font-size: clamp(0.7rem, 1.2vw, 1rem);
  }
}

@media (min-width: 1200px) {
  .secondary-view {
    width: 15vw;
    height: calc(15vw * 0.75);
    max-width: 350px;
    max-height: 262.5px;
    bottom: 10vh;
    right: 5vw;
  }
}
